<template>
  <div class="news"></div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'News',
}
</script>
<style lang="scss" scoped></style>

<template>
  <div class="about width" style="font-size: 0" v-show="show">
    <!-- <img class="about_09" src="https://oss.topfavor.cn/assets/pc/about_09.png" /> -->
    <video class="about_09" autoplay :muted='isMuted' loop="loop" playsinline controls>
        <source src="https://oss.topfavor.cn/assets/pc/movie_02.mp4" type="video/mp4" />
      </video>
    <img class="about_10" src="https://oss.topfavor.cn/assets/pc/about_10.png" />
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: 'About',
  data() {
    return {
      isMuted: true,
      show: false
    }
  },
  mounted() {
    this.show = true
    let video = document.querySelectorAll('video')[0]
    const _this = this
    document.addEventListener(
      'WeixinJSBridgeReady',
      function () {
        _this.isMuted = false
        video.play()
      },
      false
    )
  },
}
</script>
<style lang="scss" scoped>
.about_09 {
  width: 8.47rem;
  height: 2.305rem;
  margin: 1.135rem auto 0.475rem;
  display: block;
}
.about_10 {
  width: 8.1rem;
  height: 5.925rem;
  margin: 0.475rem auto 1.135rem;
  display: block;
}
</style>

<template>
  <div class="about width" style="font-size: 0; overflow: hidden;">
    <div class="product" v-show="!isM">
      <img class="product_01" @click='changeM' src="https://oss.topfavor.cn/assets/pc/product_01.png" />
      <img class="product_02" src="https://oss.topfavor.cn/assets/pc/product_02.png" />
      
      <div class="product_banner">
        <img class="product_left" @click="toggle('prev')" src="https://oss.topfavor.cn/assets/pc/product_left.png" />
        <img class="product_right" @click="toggle('next')" src="https://oss.topfavor.cn/assets/pc/product_right.png" />
        <div class="product_banner_inner">
          <img :src="productList[productIndex].banner" />
        </div>
      </div>
      <div class="product_banner_2">
        <img class="product_04" :src="productList[productIndex].tab"/>
      </div>
      <div class="product_banner_3">
        <div class="small_list">
          <div class="small_item" @click=changeIndex(index) :class="{active: index==productIndex}" v-for="(item, index) in smallBannerList" :key="index">
            <img :src="item" />
          </div>
        </div>
      </div>
      <div class="product_banner_4">
        <div class="product_item" @click="changwawa(item)" :key="index" v-for="(item, index) in productList[productIndex].clothes">
          <img class="product_item_bg" :src="item.img" />
          <img class="product_item_small" :src="item.tab" />
        </div>
      </div>
    </div>
    <div v-show="isM">
      <div>
      <img class="about_01" src="https://oss.topfavor.cn/assets/pc/about_01.png" />
    </div>
    <div class="about_body">
      <div class="about_left">
        <img class="about_02" :src="productList[productIndex].clothes[productIndex2].bannerList[wawaIndex]"/>
        <div class="list_box">
          <div class="list">
            <div :style="{marginLeft: '-' + left}">
              <img :src="item" @click='changewawaIndex(index)' :class="{active: index == wawaIndex2}" :key="index" v-for="(item, index) in productList[productIndex].clothes[productIndex2].tablist" />
            </div>
          </div>
          <img class="product_x_left" @click="toggle1('prev')" src="https://oss.topfavor.cn/assets/pc/product_left.png" />
          <img class="product_x_right" @click="toggle1('next')" src="https://oss.topfavor.cn/assets/pc/product_right.png" />
        </div>
      </div>
      <div class="about_right">
        <div class="procut_name">
          <div>{{productList[productIndex].clothes[productIndex2].name}}</div>
          <div class="product_price">￥{{productList[productIndex].clothes[productIndex2].price}}</div>
        </div>
        <div class="product_code">款号:{{productList[productIndex].clothes[productIndex2].code}}</div>
        <!-- <img class="about_04" src="https://oss.topfavor.cn/assets/pc/about_04.png" /> -->
        <img class="about_05" src="https://oss.topfavor.cn/assets/pc/about_05.png" />
        <img class="about_06" src="https://oss.topfavor.cn/assets/pc/about_06.png" />
        <img class="about_07" :src="productList[productIndex].banner"/>
        <div class="product_from">
          <div>来源于 TOPFAVOR 2022 A/W {{productList[productIndex].name}}系列</div>
          <div  @click="more"><span class="more">查看更多系列单品</span>➝</div>
        </div>
        <!-- <img class="about_08" src="https://oss.topfavor.cn/assets/pc/about_08.png" /> -->
      </div>
    </div>
    <img class="about_line" src="https://oss.topfavor.cn/assets/pc/about_line.png" />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: 'About',
  data() {
    return {
      isMuted: true,
      productIndex: 0,
      productIndex2: 0,
      wawaIndex: 0,
      wawaIndex2: 0,
      left: 0,
      smallBannerList: [
      'https://oss.topfavor.cn/assets/banner/small_01.jpg',
        'https://oss.topfavor.cn/assets/banner/small_02.jpg',
        'https://oss.topfavor.cn/assets/banner/small_03.jpg',
        'https://oss.topfavor.cn/assets/banner/small_04.jpg',
        'https://oss.topfavor.cn/assets/banner/small_05.jpg',
        'https://oss.topfavor.cn/assets/banner/small_06.jpg',
        'https://oss.topfavor.cn/assets/banner/small_07.jpg'
      ],
      productList: [{
        banner: 'https://oss.topfavor.cn/assets/banner/01.jpg',
        tab: 'https://oss.topfavor.cn/assets/banner/tab_01.png',
        name: 'BASEBALL STAR',
      
        clothes: [{
          img: 'https://oss.topfavor.cn/assets/banner/banner_01_01.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_01_01.png',
          price: '1,680',
          name: '「Cute Batter 王牌投手」棉服夹克',
          code: 'TF22AW03S07',
          pid:'TP8310',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_01_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_01_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_01_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_01_01_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_01_01_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_01_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_01_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_01_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_01_02.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_01_02.png',
          price: '1,680',
          name: '「Ace Pitcher 王牌投手」魔术贴棒球服',
          code: 'TF22AW03S07',
          pid:'TP7585',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_01_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_01_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_01_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_01_02_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_01_02_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_01_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_02_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_02_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_01_03.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_01_03.png',
          price: '1,780',
          name: '「BEST CATCHER 王牌投手」魔术贴棒球服',
          pid:'TP5488',
          code: 'TF22AW03S07',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_01_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_01_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_01_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_01_03_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_01_03_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_01_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_03_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_01_03_05.jpg',
          ]
        }] 
      }, {
        banner: 'https://oss.topfavor.cn/assets/banner/02.jpg',
        tab: 'https://oss.topfavor.cn/assets/banner/tab_02.png',
        name: 'SWEET GALAXY',
       
        clothes: [{
          img: 'https://oss.topfavor.cn/assets/banner/banner_02_01.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_02_01.png',
          price: '2,580',
          name: '「HONEYDEY PLANET」立领鹅绒服',
          pid:'TP6816',
          code: 'TF22AW03S07',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_02_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_02_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_02_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_02_01_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_02_01_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_02_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_01_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_01_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_02_02.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_02_02.png',
          price: '2,680',
          name: '「STRAWBERRY PLANET」面包鹅绒服',
          code: 'TF22AW03S07',
          pid:'TP6040',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_02_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_02_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_02_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_02_02_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_02_02_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_02_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_02_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_02_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_02_03.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_02_03.png',
          price: '2,780',
          name: '「LEMON PLANET」毛毛拼接鹅绒服',
          pid:'TP0976',
          code: 'TF22AW03S07',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_02_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_02_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_02_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_02_03_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_02_03_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_02_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_03_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_02_03_05.jpg',
          ]
        }] 
      }, {
        banner: 'https://oss.topfavor.cn/assets/banner/03.jpg',
        tab: 'https://oss.topfavor.cn/assets/banner/tab_03.png',
        name: 'GO ALL THE WAR',
        clothes: [{
          img: 'https://oss.topfavor.cn/assets/banner/banner_03_01.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_03_01.png',
          price: '1,380',
          name: '「POLARIS」针织开衫',
          code: 'TF22AW03S07',
          pid:'TP4696',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_03_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_03_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_03_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_03_01_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_03_01_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_03_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_01_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_01_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_03_02.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_03_02.png',
          price: '2,080',
          name: '「EXTREME LIGHT CHASING」羊毛刺绣棉服',
          code: 'TF22AW03S07',
          pid:'TP9572',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_03_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_03_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_03_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_03_02_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_03_02_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_03_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_02_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_02_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_03_03.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_03_03.png',
          price: '2,180',
          name: "「GIGI'S BEAR PAW」短款鹅绒外套",
          pid:'TP4431',
          code: 'TF22AW03S07',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_03_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_03_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_03_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_03_03_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_03_03_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_03_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_03_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_03_03_05.jpg',
          ]
        }] 
      }, {
        banner: 'https://oss.topfavor.cn/assets/banner/04.jpg',
        tab: 'https://oss.topfavor.cn/assets/banner/tab_04.png',
        name: 'GANDY HOUSE',
        clothes: [{
          img: 'https://oss.topfavor.cn/assets/banner/banner_04_01.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_04_01.png',
          price: '2,080',
          name: "「PINK MARSHMALLOW」拼接皮毛夹克",
          code: 'TF22AW03S07',
          pid:'TP9388',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_04_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_04_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_04_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_04_01_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_04_01_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_04_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_01_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_01_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_04_02.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_04_02.png',
          price: '1,080',
          name: "「COLORFUL CANDY」圆领撞色毛衣",
          code: 'TF22AW03S07',
          pid:'TP1174',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_04_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_04_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_04_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_04_02_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_04_02_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_04_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_02_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_02_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_04_03.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_04_03.png',
          price: '980',
          name: "「MYSTERIOUS MACARONE」魔术贴卫衣",
          code: 'TF22AW03S07',
          pid:'TP7637',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_04_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_04_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_04_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_04_03_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_04_03_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_04_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_03_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_04_03_05.jpg',
          ]
        }] 
      }, {
        banner: 'https://oss.topfavor.cn/assets/banner/05.jpg',
        tab: 'https://oss.topfavor.cn/assets/banner/tab_05.png',
        name: 'BUILDING BLOCK MAZE',
      
        clothes: [{
          img: 'https://oss.topfavor.cn/assets/banner/banner_05_01.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_05_01.png',
          price: '1,880',
          name: "「GEOMETRY GAME」拼接牛仔外套",
          code: 'TF22AW03S07',
          pid:'TP4672',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_05_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_05_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_05_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_05_01_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_05_01_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_05_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_05_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_05_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_05_01_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_05_01_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_05_02.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_05_02.png',
          price: '1,860',
          name: "「GEOMETRY GAME」拼接牛仔裤",
          code: 'TF22AW03S07',
          pid:'TP4211',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_05_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_05_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_05_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_05_02_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_05_02_05.png',
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_05_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_05_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_05_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_05_02_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_05_02_05.jpg',
          ]
        }]
      }, {
        banner: 'https://oss.topfavor.cn/assets/banner/06.jpg',
        tab: 'https://oss.topfavor.cn/assets/banner/tab_06.png',
        name: 'STARRY NIGHT',
     
        clothes: [{
          img: 'https://oss.topfavor.cn/assets/banner/banner_06_01.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_06_01.png',
          price: '2,580',
          name: "「STARRY NIGHT」灯芯长款鹅绒服",
          code: 'TF22AW03S07',
          pid:'TP5159',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_06_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_01_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_06_01_05.png'
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_06_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_01_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_01_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_06_02.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_06_02.png',
          price: '1,560',
          name: "「NIGHT SKY」魔术贴黑色卫裤",
          code: 'TF22AW03S07',
          pid:'TP9409',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_06_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_02_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_06_02_05.png'
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_06_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_02_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_02_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_06_03.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_06_03.png',
          price: '1,180',
          name: "「NIGHT SKY」护黑色卫衣",
          code: 'TF22AW03S07',
          pid:'TP5276',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_06_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_03_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_06_03_05.png'
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_06_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_03_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_03_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_06_04.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_06_04.png',
          price: '1,080',
          name: "「HIGHT SKY」黑色卫衣& METEOR SHOWER 黑色牛仔裤",
          code: 'TF22AW03S07',
          pid:'TP4291',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_06_04_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_04_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_04_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_06_04_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_06_04_05.png'
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_06_04_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_04_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_04_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_04_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_06_04_05.jpg',
          ]
        }]
      }, {
        banner: 'https://oss.topfavor.cn/assets/banner/07.jpg',
        tab: 'https://oss.topfavor.cn/assets/banner/tab_07.png',
        name: '-32.5℃',
        clothes: [{
          img: 'https://oss.topfavor.cn/assets/banner/banner_07_01.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_07_01.png',
          price: '1,780',
          name: "「CLACIER」牛仔外套",
          code: 'TF22AW03S07',
          pid:'TP0201',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_07_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_01_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_07_01_05.png'
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_07_01_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_01_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_01_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_01_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_01_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_07_02.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_07_02.png',
          price: '1,380',
          name: "「AURORA」套头混纺毛衣",
          code: 'TF22AW03S07',
          pid:'TP0567',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_07_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_02_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_07_02_05.png'
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_07_02_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_02_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_02_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_02_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_02_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_07_03.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_07_03.png',
          price: '1,660',
          name: "「SEA ICE」长款卫裤",
          pid:'TP2709',
          code: 'TF22AW03S07',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_07_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_03_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_07_03_05.png'
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_07_03_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_03_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_03_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_03_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_03_05.jpg',
          ]
        }, {
          img: 'https://oss.topfavor.cn/assets/banner/banner_07_04.png',
          tab: 'https://oss.topfavor.cn/assets/banner/tab_07_04.png',
          price: '780',
          name: "「SNOW COVER」长袖T恤",
          pid:'TP1308',
          code: 'TF22AW03S07',
          bannerList: [
            'https://oss.topfavor.cn/assets/wawa/banner_07_04_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_04_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_04_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/banner_07_04_04.png',
            'https://oss.topfavor.cn/assets/wawa/banner_07_04_05.png'
          ],
          tablist: [
            'https://oss.topfavor.cn/assets/wawa/tab_07_04_01.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_04_02.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_04_03.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_04_04.jpg',
            'https://oss.topfavor.cn/assets/wawa/tab_07_04_05.jpg',
          ]
        }]
      }]
    }
  },
  computed: {
    isM() {
        return this.$store.state.isM2
      }
  },
  methods: {
    changeM() {
      // this.$store.commit('changeM', true)
    },
    changeIndex(index) {
      this.productIndex = index
    },
    more() {
      this.$store.commit('changeM2', false)
    },
    changwawa(item) {
      //this.productIndex2 = index
      this.$router.push({path:'/product',query:{pid:item.pid}})
      // this.wawaIndex = 0
      // this.wawaIndex2 = 0
     
      // this.$store.commit('changeM2', true)
      // this.left = 0
      // window.scrollTo(220, 0); 
    },
    changewawaIndex(index) {
      this.wawaIndex = index 
      this.wawaIndex2 = index
    },
    toggle1(type) {
      if (type == 'prev') {
          this.left = 0
      } else {
        this.left = '0.81rem'
      }
      // let { wawaIndex } = this
      // if (type == 'prev') {
      //   if (wawaIndex > 0) {
      //     wawaIndex = wawaIndex - 1
      //   } else {
      //     wawaIndex = 3
      //   }
      // } else {
      //   if (wawaIndex < 3) {
      //     wawaIndex = wawaIndex + 1
      //   } else {
      //     wawaIndex = 0
      //   }
      // }
      // this.wawaIndex = wawaIndex
    },
    toggle(type) {
      let { productIndex, productList } = this
      if (type == 'prev') {
        if (productIndex > 0) {
          productIndex = productIndex - 1
        } else {
          productIndex = productList.length - 1
        }
      } else {
        if (productIndex < productList.length - 1) {
          productIndex = productIndex + 1
        } else {
          productIndex = 0
        }
      }
      this.productIndex = productIndex
    }
  },
  mounted() {
    let video = document.querySelectorAll('video')[0]
    const _this = this
    document.addEventListener(
      'WeixinJSBridgeReady',
      function () {
        _this.isMuted = false
        video.play()
      },
      false
    )
  },
}
</script>
<style lang="scss" scoped>
  .product_from {
    color: #222222;
    font-size: 0.12rem;
    line-height: 0.18rem;
    padding-left: 0.18rem;
    margin: 0.14rem auto 0.54rem;
    .more {
      font-weight: 600;
      font-size: 0.12rem;
      color: #000;
      border-bottom: 1px solid #000;
      line-height: 0.18rem;
      display: inline-block;
      margin-top: 0.05rem;
      margin-right: 0.02rem;
      cursor: pointer;
    }
  }
  .procut_name {
    font-size: 0.18rem;
    line-height: 0.24rem;
    font-weight: 600;
  }
  .product_price {
    font-weight: 400;
    line-height: 0.3rem;
  }
  .product_code {
    font-size: 0.14rem;
    line-height: 0.3rem;
    color: #EFEFEF;
    border-bottom: 2px solid #EFEFEF;
  }
.small_list {
  display: flex;
  justify-content: center;
  cursor: pointer;
  .small_item {
    width: 0.6rem;
    padding: 0.1rem 0.04rem;
    &.active {
      background-color: #EFEFEF;
    }
    img {
      width: 100%;
      height: 0.34rem;
    }
  }
}
.product_left,
.product_right {
  position: absolute;
  width: 0.23rem !important;
  height: 0.41rem !important;
  top: 0.96rem;
  cursor: pointer;
}
.product_left {
  left: -0.89rem;
}
.product_right {
  right: -0.89rem;
}
.product_banner_4 {
  display: flex;
  justify-content: center;
  margin-bottom: 0.6rem;
  cursor: pointer;
  .product_item + .product_item {
    margin-left: 1.03rem;
  }
  .product_item_bg {
    width: auto;
    height: 2.36rem;
    margin: 0rem auto 0.14rem;
    display: block;
  }
  .product_item_small {
    width: auto;
    height: 0.19rem;
    margin: 0rem auto;
    display: block;
  }
}
.product_banner_3 {
  height: 0.58rem;
  margin: 0.3rem auto 0.63rem;
  display: block;
}
.product_banner_2 {
  margin: 0.3rem auto 0.3rem;
  display: block;
  img {
    width: auto;
    height: 0.67rem;
    display: block;
    margin: 0 auto;
  }
}
.product_banner {
  position: relative;
  width: 4.14rem;
  height: 2.13rem;
  margin: 0.77rem auto 0.3rem;
  display: block;
  img {
    width: 4.14rem;
    height: 2.13rem;
  }
}
.product_01 {
  width: 8.45rem;
  height: 4.83rem;
  margin: 1.10rem auto 0.3rem;
  display: block;
  cursor: pointer;
}
.product_02 {
  width: 3.15rem;
  height: 0.45rem;
  margin: 0.3rem auto 0.77rem;
  display: block;
}
.about_line {
    width: 8.05rem;
    height: 0.01rem;
    margin: 0 auto 1.39rem;
    display: block;
  }
.about_body {
  display: flex;
  justify-content: space-between;
  padding-left: 1.13rem;
  padding-right: 1.77rem;
  .about_right {
    margin-left: 0.5rem;
    img {
      display: block;
    }
  }
  .about_02 {
    width: 3.76rem;
    height: 3.76rem;
    // margin: 0.2rem 0 0.25rem 0.76rem;
    display: block;
    border: 1px solid #9a9a9a;
  }
  .about_03 {
    width: 2.43rem;
    height: 0.48rem;
    margin: 0.03rem 0 0.11rem;
  }
  .about_04 {
    width: 2.37rem;
    height: 0.18rem;
    margin: 0.11rem 0 0.32rem;
  }
  .about_05 {
    width: 1.28rem;
    height: 1.29rem;
    margin: 0.32rem auto 0.33rem;
  }
  .about_06 {
    width: 2.15rem;
    height: 0.37rem;
    margin: 0.33rem auto 0.17rem;
  }
  .about_07 {
    width: 2.15rem;
    height: 1.21rem;
    margin: 0.17rem auto 0.14rem;
  }
  .about_08 {
    width: 2.07rem;
    height: 0.3rem;
    margin: 0.14rem auto 0.54rem;
  }
  .about_left {
    width: 3.76rem;
  }
  .list_box {
    position: relative;
    width: 3.13rem;
    margin: 0 auto;
    .product_x_left,
    .product_x_right {
      position: absolute;
      width: 0.165rem !important;
      height: 0.30rem !important;
      top: 0.52rem;
      cursor: pointer;
      border: none;
    }
    .product_x_left {
      left: -0.34rem;
    }
    .product_x_right {
      right: -0.34rem;
    }
  }
  .list {
    padding-top: 0.31rem;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {

display: none; /* Chrome Safari */

}
    img {
      width: 0.7rem;
      height: 0.7rem;
      margin-right: 0.11rem;
      box-sizing: border-box;
      border: 2px solid transparent;
      cursor: pointer;
      margin-top: -2px;
      border: 1px solid #9a9a9a;
      // &.active {
      //   border: 1px solid #9a9a9a;
      // }
    }
  }
}
.about_01 {
  width: 8.08rem;
  height: 0.24rem;
  margin: 1.49rem auto 0.46rem;
  display: block;
}
</style>

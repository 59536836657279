<template>
    <div class="auth">
        <div class="yes" v-if="isAuth">
            <img class="yes_img" src="https://oss.topfavor.cn/assets/b.gif">
            <!-- <img class="yes_icon" src="https://oss.topfavor.cn/assets/b_01.jpg"> -->
        </div>
        <div class="no" v-else>
            <img class="no_img" src="https://oss.topfavor.cn/assets/a.gif">
            <!-- <img class="no_icon" src="https://oss.topfavor.cn/assets/a_01.jpg"> -->
        </div>
        <div class="code_box">
            <Code></Code>
        </div>
    </div>
</template>
<script>
import Code from '@/components/Code'
export default {
    name: 'Auth',
    data() {
        return {
            isAuth: true
        }
    },
    components: {
        Code
    },
    created() {
        console.log(this.$route.query.id)
    }
}
</script>
<style scoped lang="scss">
.code_box {
   padding: 0.6rem 0 0.6rem 0;
}
.yes {
    overflow: hidden;
    width: 7.3rem;
    margin: 1rem auto 0.5rem;
    .yes_img {
        float: left;
        width: 7.3rem;
        height: 7.3rem;
        &::after {
            clear: both;
        }
        margin-bottom: 0.5rem;
    }
    .yes_icon {
        width: 6.38rem;
        height: 0.56rem;
        display: block;
        margin: 0.5rem auto 0;
    }
}
.no {
    overflow: hidden;
    width: 5.57rem;
    margin: 1rem auto 0.5rem;
    .no_img {
        float: left;
        width: 5.57rem;
        height: 5.61rem;
        &::after {
            clear: both;
        }
        margin-bottom: 0.5rem;
    }
    .no_icon {
        width: 4.67rem;
        height: 0.55rem;
        display: block;
        margin: 0.5rem auto 0;
    }
}
@media screen and (min-width: 320px) and (max-width: 640px){
  .no {
    width: 2.785rem;
    margin-top: 2rem;
    .no_img {
        width: 2.785rem;
        height: 2.805rem;
    }
    .no_icon {
        width: 2.335rem;
        height: 0.275rem;
    }
}
.yes {
    width: 3.65rem;
    // margin-top: 2rem;
    .yes_img {
        width: 3.65rem;
        height: 3.65rem;
    }
    .yes_icon {
        width: 3.19rem;
        height: 0.28rem;
    }
}
}
</style>